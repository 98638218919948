<template>
  <button @click="$emit('click')" v-ripple class="fat-button" :class='{ padding }' :style='{ width, maxWidth }'>
    <slot></slot>
  </button>
</template>

<script>
export default {
  emits: ['click'],
  props: {
    maxWidth: String,
    width: String,
    padding: Boolean,
  }
}
</script>

<style scoped lang="scss">
.fat-button {
  background: var(--color-black);
  color: var(--color-off-white);
  cursor: pointer;
  padding: 12px;
  border: none;
  border-radius: var(--border-radius-1);
  position: relative;
  text-transform: uppercase;
  user-select: none;
  font-weight: 800;
  font-size: var(--fs-1);
  letter-spacing: 1px;
}

.padding {
}
</style>
