<template>
  <div>
    <form @submit.prevent="search('search')">
      <ButtonOpenToolModal />

      <InputSearchTerm />

      <InputDates />

      <InputContainer :label="$t('setting.pickWebsite')">
        <input type="text" v-model="websiteSearch" placeholder="Input site name..." class="search-bar" autofocus>
        <div class="input-card selector">
          <div class="websites">
            <div
            v-for="(website, i) in filteredWebsites"
              :key="website.name"
              class="website-row"
              @click="toggle(i, website)"
            >
              <WebsiteIcon :website="website.name" class="website-icon" />
              <div class="website-text">{{ website.label }}</div>
              <q-radio
                :val="true"
                :model-value="website.active"
                @update:model-value="toggle(i, website)"
                color="purple-highlight"
              />
            </div>
          </div>
        </div>
      </InputContainer>

      <FatButton width='100%' :disabled=!hasConsented>
        {{ $t("setting.button.search") }}
      </FatButton>
    </form>
  </div>
</template>

<script>
import ButtonOpenToolModal from '@/components/input/tool-picker/ButtonOpenToolModal'
import { createNamespacedHelpers } from 'vuex'
import InputSearchTerm from '@/components/input/InputSearchTerm'
import InputDates from '@/components/input/InputDates'
import FatButton from '@/components/input/FatButton'
import InputContainer from '@/components/input/InputContainer'
import WebsiteIcon from '@/components/WebsiteIcon'
const { mapGetters, mapMutations } = createNamespacedHelpers('searchSettings')

export default {
  components: {
    ButtonOpenToolModal,
    InputContainer,
    InputSearchTerm,
    InputDates,
    FatButton,
    WebsiteIcon
  },
  data () {
    return {
      websiteSearch: '',
    }
  },
  computed: {
    ...mapGetters(['websites', 'hasConsented']),
    filteredWebsites () { // Add this computed property
      if (!this.websiteSearch) {
        return this.websites
      }
      const searchLower = this.websiteSearch.toLowerCase()
      return this.websites.filter(website =>
        website.name.toLowerCase().includes(searchLower)
      )
    }
  },
  methods: {
    ...mapMutations(['search', 'resetWebsitesActiveState', 'setWebsiteActive']),
    toggle (i, website) {
      this.resetWebsitesActiveState(website)
      const index = this.websites.findIndex(w => w.name === website.name)
      this.setWebsiteActive({ i: index, val: !website.active })
    }
  }
}
</script>

<style scoped lang="scss">
.search-bar {
  width: 100%;
  height: 40px;
  font: var(--primary-font);
}

form > * {
  margin-top: var(--spacing-5);
}

.selector {
  color: var(--color-dark-text);
}

.websites {
  width: 100%;
  height: 600px;
  overflow-y: scroll;

  .website-row {
    width: 97%;
    display: grid;
    grid-template-columns: auto 1fr auto;
    justify-items: start;
    align-items: center;
    text-align: start;

    cursor: pointer;
    user-select: none;
    padding: var(--spacing-0) 0;

    .subtext {
      grid-column: 2 / 3;

      color: var(--color-light-text);
      line-height: 1.5;
      padding-bottom: 6px;
    }
  }

  .website-row + .website-row {
    border-top: solid 1px var(--color-dividers);
  }

  .website-icon {
    margin: 0 var(--spacing-2);
  }
}

</style>
