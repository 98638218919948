<template>
  <InputContainer :label="$t('setting.pickWebsite')">
    <input type="text" v-model="search" placeholder="Input site name..." class="search-bar" autofocus>
    <div class="input-card selector">
      <div class="websites">
        <div
          v-for="(website, i) in filteredWebsites"
          :key="website.name"
          :class="['website-row', { 'last-active': isLastActive(i) }]"
          @click="toggle(i, website)"
        >
          <WebsiteIcon :website="website.name" class="website-icon" />
          <div class="website-text">{{ website.label }}</div>
          <q-checkbox
            :model-value="website.active"
            @update:model-value="toggle(i, website)"
            color="black-highlight"
          />
        </div>
      </div>
    </div>
  </InputContainer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import InputContainer from './InputContainer'
import WebsiteIcon from '@/components/WebsiteIcon'
const { TWITTER } = require('@/constants/sites')

const { mapGetters, mapMutations } = createNamespacedHelpers('searchSettings')

export default {
  components: {
    InputContainer,
    WebsiteIcon
  },
  data () {
    return {
      search: '',
    }
  },
  computed: {
    ...mapGetters(['websites']),
    filteredWebsites () { // Add this computed property
      if (!this.search) {
        return [...this.websites].sort((a, b) => b.active - a.active)
      }
      const searchLower = this.search.toLowerCase()
      return [...this.websites]
        .filter(website => website.name.toLowerCase().includes(searchLower))
        .sort((a, b) => b.active - a.active)
    },
  },
  methods: {
    ...mapMutations(['setWebsiteActive']),
    toggle (i, website) {
      const index = this.websites.findIndex(w => w.name === website.name)
      this.setWebsiteActive({ i: index, val: !website.active })
      this.search = ''
    },
    isLastActive (i) {
      return this.filteredWebsites[i].active && (i === this.filteredWebsites.length - 1 || !this.filteredWebsites[i + 1].active)
    },
    isTwitter (website) {
      return website.name === TWITTER
    }
  }
}
</script>

<style scoped lang="scss">
.selector {
  color: var(--color-dark-text);
}

.websites {
  width: 100%;
  height: 600px;
  overflow-y: scroll;
}

.website-row {
  width: 97%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  justify-items: start;
  align-items: center;
  text-align: start;

  cursor: pointer;
  user-select: none;
  padding: var(--spacing-0) 0;

  .subtext {
    grid-column: 2 / 3;

    color: var(--color-light-text);
    line-height: 1.5;
    padding-bottom: 6px;
  }
}

.website-row + .website-row {
  border-top: solid 1px var(--color-dividers);
}

.website-icon {
  margin: 0 var(--spacing-2);
}
.search-bar {
  width: 100%;
  height: 40px;
  font-family: var(--primary-font);
  // color: var(--color-purple);
  color: var(--color-dark-text)
}
.selected-website + .website-row {
  border-top: 2px solid black;
}
.last-active {
  border-bottom: 1px dashed black;
}
</style>
