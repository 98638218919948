<template>
  <div>
    <slot
      :lastSearchSettings="lastSearchSettings"
      :results="results"
      :resultsAvailable="results.length > 0"
      :loading="queriesRunning > 0"
      :error="error"
    />
  </div>
</template>

<script>
import { cloneDeep, isEmpty } from 'lodash'
import { createNamespacedHelpers } from 'vuex'
import { queries as buildQueries } from '@/helpers/searchQuery'
const { GAB } = require('@/constants/sites')

const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} = createNamespacedHelpers('searchSettings')

export default {
  props: {
    page: String // e.g. "hashtags"
  },
  data () {
    return {
      queriesRunning: 0,
      results: [],
      error: null,
      lastSearchSettings: {}
    }
  },
  computed: {
    ...mapState(['settings', 'doSearch', 'history']),
    ...mapGetters(['websites'])
  },
  methods: {
    ...mapMutations(['setDoneSearchBefore', 'setLastUsedTool']),
    ...mapActions(['loadSettings']),
    search (updateUrl = true) {
      // TODO - move this into vuex action
      const queries = buildQueries(this.settings, this.page)
      this.queriesRunning = queries.length
      this.results = []

      queries.forEach(query => {
        const startTime = Date.now()
        console.info('API', query.name, '-->')

        return fetch(query.url)
          .then(response => {
            console.info('API', query.name, '<--', `${Math.floor((Date.now() - startTime) / 100) / 10}s`, query.url)
            if (response.status === 403) { // 403 = Unauthorized
              throw new Error('You are not authorized to request data from this time period. Please reach out info@openmeasures.io')
            } else if (response.status !== 200) { // 200 = OK
              throw new Error(`${response.status} - ${response.statusText}, type: ${response.type}`)
            }
            return response.json()
          })
          .then((data) => {
            this.results = [...this.results, { ...query, data }]
          })
          .then(() => this.queryDone(updateUrl))
          .catch(this.queryFail)
      })
    },
    queryDone (updateUrl) {
      this.queriesRunning = this.queriesRunning - 1
      if (this.queriesRunning) return

      this.lastSearchSettings = cloneDeep(this.settings)

      if (updateUrl) this.updateUrl()
      this.setDoneSearchBefore()
    },
    queryFail (err) {
      this.queriesRunning = 0
      this.error = err
      console.error(err)
    },
    updateUrl () {
      const query = this.lastSearchSettings

      // haven't made a search with this tool yet
      if (query === null) return

      // the default url-ification of the websites object is weird so we'll
      // make it nicer
      const websites = query.websites
        .filter((website) => website.active)
        .map((website) => website.name)
        .join(',')

      const cleanQuery = { ...query, websites }

      // TODO make query handling more consitent across app
      if (cleanQuery.changepoint !== undefined) {
        cleanQuery.changepoint = Boolean(cleanQuery.changepoint)
      }

      const a = new URLSearchParams(this.$route.query)
      a.sort()
      const b = new URLSearchParams(cleanQuery)
      b.sort()

      if (a.toString() === b.toString()) return

      this.$router.replace({ query: cleanQuery })
    }
  },
  watch: {
    // when the user clicks Search, this variable is incremented, so we watch
    // it to find out when the user wants to search. a weird kind of event bus
    'doSearch.count': function () {
      if (this.doSearch.page === this.page) {
        this.search()
      }
    },
    $route (newRoute) {
      // to know what tool to show when we go from About -> Home
      this.setLastUsedTool(this.page)
      if (!isEmpty(newRoute.query)) {
        const params = newRoute.query
        const paramWebsites = (params.websites || GAB).split(',')
        const fixedWebsites = this.websites.map((website) => ({
          ...website,
          active: paramWebsites.includes(website.name)
        }))
        this.loadSettings({ ...params, websites: fixedWebsites })
      }
      // watch the route to see if we go back to this tool from another one,
      // and then run updateUrl again to get the right query params

      // only if we're going to this page, and only when we haven't just
      // made a search (and therefore called updateUrl too)
      if (newRoute.name === this.page && isEmpty(newRoute.query)) {
        this.updateUrl()
      }
    }
  },
  created () {
    // if there are query params in the url when the page is loaded,
    // then grab those and perform a search with them

    const params = this.$route.query

    if (!isEmpty(params)) {
      // TODO move this logic into vuex
      // consider making changes to route an action?
      // sitll need something like this to load params from a URL into state tho
      const paramWebsites = (params.websites || GAB).split(',')

      // turn it back to the actual format that we use
      const fixedWebsites = this.websites.map((website) => ({
        ...website,
        active: paramWebsites.includes(website.name)
      }))

      this.loadSettings({ ...params, websites: fixedWebsites })
    }

    this.search()
  }
}
</script>

<style scoped lang="scss"></style>
