<template>
  <q-toolbar class="toolbar">
    <q-toolbar-title
      class="title"
    >
    <div class="parent">
      <a :href="'https://openmeasures.io'" target="_blank">
        <img :src='omLogo' class='om-logo' />
      </a>
    </div>
    </q-toolbar-title>
    <q-space />

    <q-btn
      v-if="!isHome"
      class="home-mobile-menu"
      icon="menu"
      unelevated
      dense
      @click="$emit('toggleMenu')"
    />
    <q-btn
      v-if="isHome"
      class='edit-query'
      label='Edit Query'
      unelevated
      dense
      color="purple-highlight"
      @click="$emit('toggleToolBar')"
      style='font-size: 10px; line-height: 13px; padding: 3px 10px;'
    />

    <div class="right-part">
      <div
        class="right-part-nav">
        <a href='https://blog.openmeasures.io/' target='_blank'>
          <ButtonInner>
            <span class='top-bar-link'>{{ $t("nav.blog") }}</span>
          </ButtonInner>
        </a>

        <a href='https://docs.openmeasures.io/docs' target='_blank'>
          <ButtonInner>
            <span class='top-bar-link'>{{ $t("nav.docs") }}</span>
          </ButtonInner>
        </a>
        <a href='https://form.typeform.com/to/EexZftxx' target='_blank'>
          <ButtonInner class="purple">
            <span class='top-bar-link'>{{ $t("nav.contact") }}</span>
          </ButtonInner>
        </a>
      </div>
      <div class="right-part-lang">
        <LanguagePickerDropdown />
      </div>
    </div>
  </q-toolbar>
</template>

<script>
import ButtonInner from '@/components/input/ButtonInner'
import LanguagePickerDropdown from '@/components/input/LanguagePickerDropdown'
import omLogo from '@/assets/open-measures-logo-full.svg'
import homeLogo from '@/assets/om-logo.svg'
const { TIMELINE, HASHTAGS, LINKS, ACTIVITY, SEARCH } = require('@/constants/tools')
const { ENV, PRODUCTION } = require('@/constants/misc')

export default {
  data () {
    return {
      omLogo,
      homeLogo,
      env: ENV,
      large: true,
      showENV: ENV !== PRODUCTION
    }
  },
  computed: {
    isHome () {
      return [TIMELINE, HASHTAGS, LINKS, ACTIVITY, SEARCH].includes(this.$route.name)
    },
  },
  methods: {
    updateLarge () {
      this.large = window.matchMedia('(min-width: 1000px)').matches
    },
  },
  components: {
    ButtonInner,
    LanguagePickerDropdown
  }
}
</script>

<style scoped lang="scss">
.purple {
  color: var(--color-white);
  background-color: var(--color-purple);
  // border-color: var(--color-purple);
  // border: 3px solid var(--color-purple);  /* Adjust as needed */
  // background-color: transparent;
  margin: 5px;
}

.parent {
  display: flex;
  align-items: center;
  /* other styles... */
}
.toolbar {
  background: var(--color-dark-bg);

  .router-link-active {
    text-decoration: none !important;
  }

  .toolbar-back {
    font-size: 24px;
    font-weight: bold;
  }

  .title {
    font-weight: 800;

    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 10px;
    align-items: center;

    img.om-logo {
      height: 22px;
    };

    div.env {
      color: red;
    }
  }

  .home-mobile-menu {
    display: none;
  }

  .edit-query {
    display: none;
    margin-right: 2em;
    padding: 0 5px;
  }

  .right-part {
    display: flex;
    align-items: center;

    .right-part-nav {
      display: flex;
      align-items: center;

      > * {
        margin-right: 3rem;
      }
    }
    a {
      font-weight: bold;
      color: var(--color-white);

      &.router-link-exact-active {
        color: gray;
      }
    }

    .api-button { cursor: pointer; }
  }

  .top-bar-link {
    font-size: 12px;
  }
}

@media (max-width: 1000px) {
  .toolbar .edit-query {
    display: grid;
  }
}

@media (max-width: 860px) {
  .toolbar .top-bar-link {
    font-size: 14px;
  }
}

@media (max-width: 650px) {
  .toolbar {
    .title {
      display: grid;
    }

    .edit-query {
      margin-right: 5px;
    }

    .home-mobile-menu {
      display: grid;
    }

    .right-part {
      margin-right: 1rem;
      display: none;
    }
  }
}
</style>
