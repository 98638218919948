module.exports = {
  title: 'Open Measures',
  subtitle: 'Fácil comprensión de las conversaciones en línea.',
  intro: 'Open Measures fue diseñado para apoyar a personas activistas, periodistas, investigadoras y organizaciones para analizar y visualizar tendencias amplias en diferentes plataformas.',
  value1: {
    title: 'Que tenemos',
    detail: 'Open Measures fue diseñado para apoyar a personas activistas, periodistas, investigadoras y organizaciones para analizar y visualizar tendencias amplias en diferentes plataformas. '
  },
  value2: {
    title: 'Sencilla y rigurosa',
    detail: 'Las herramientas han sido diseñadas para ser eficientes y simples, para que quienes las utilicen puedan responder rápidamente a la desinformación y las campañas de odio mientras se propagan, promoviendo que el desarrollo de investigaciones académicas sobre el tema.'
  },
  value3: {
    title: 'Open-source',
    detail: 'Nuestras herramientas son de {openSource} para que pueda ver cómo estamos trabajando y adaptarlas a sus necesidades.',
    openSource: 'open source'
  },
  contact: {
    title: 'Salúdanos',
    detail: 'Encuéntranos en {gitHosting} o salúdanos en info[at]openmeasures.io or @Open_Measures en Twitter. Open Measures está alojado fiscalmente y las contribuciones se pueden realizar a través de nuestra página de {donation}.'
  },
  partners: {
    title: 'Our Partners'
  }
}
