<template>
  <InputDropdown
    :label="$t('setting.limit')"
    :value="limit"
    @input="setLimit"
    :options="options"
  />
</template>

<script>
import InputDropdown from './InputDropdown'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapMutations } = createNamespacedHelpers('searchSettings')

export default {
  components: {
    InputDropdown
  },
  computed: {
    ...mapGetters(['limit']),
    options () {
      return new Array(9)
        .fill(0)
        .map((n, i) => i * 1000 + 1000)
        .map((num) => ({
          label: `${num}`,
          value: num
        }))
    }
  },
  methods: {
    ...mapMutations(['setLimit'])
  }
}
</script>

<style scoped lang="scss"></style>
