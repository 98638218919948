<template>
  <div>
    <div v-if="resultsAvailable">
      <CardChartTimeline
        v-for="website in resultsData"
        :key="website.label"
        :lastSearchSettings="lastSearchSettings"
        :website="website"
      />

      <TableTimeline :resultsData="resultsData" />
    </div>
    <BodyLoading :loading="loading" />
    <BodyError :error="error" />
  </div>
</template>

<script>
import { get } from 'lodash'
import moment from 'moment'
import CardChartTimeline from '@/components/charts/CardChartTimeline'
import TableTimeline from '@/components/tables/TableTimeline'
import BodyLoading from './BodyLoading'
import BodyError from './BodyError'

export default {
  components: {
    CardChartTimeline,
    TableTimeline,
    BodyLoading,
    BodyError
  },
  props: {
    lastSearchSettings: Object,
    results: Array,
    resultsAvailable: Boolean,
    loading: Boolean,
    error: Error
  },
  computed: {
    /**
     * Takes the raw result from the fetch and turns it into a nice format
     * that we can use
     */
    resultsData () {
      if (this.results !== null) {
        return this.results.map((websiteResult) => {
          const createdKey = websiteResult.data.created_key

          const changepointData = get(
            websiteResult.data,
            `aggregations.changepoint.${createdKey}`,
            []
          )

          const normalData = get(
            websiteResult.data,
            `aggregations.${createdKey}.buckets`,
            []
          )

          const dataToPoint = (t) => ({
            date: moment.utc(t.key).format('YYYY-MM-DD HH:mm:ss'),
            usage: t.doc_count
          })

          return this.lastSearchSettings.changepoint
            ? {
                label: websiteResult.label,
                before: changepointData.before.map(dataToPoint),
                after: changepointData.after.map(dataToPoint),
                name: websiteResult.name
              }
            : {
                label: websiteResult.label,
                data: normalData.map(dataToPoint),
                name: websiteResult.name
              }
        })
      } else {
        return []
      }
    }
  }
}
</script>

<style scoped lang="scss">
:deep(.card) {
  margin-top: var(--spacing-4);
}
</style>
