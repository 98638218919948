import moment from 'moment'
const { FOURCHAN, EIGHTKUN, PARLER, GAB, WIN, POAL, TELEGRAM, GETTR, BITCHUTE_VIDEO, BITCHUTE_COMMENT, MEWE, WIMKIN, RUMBLE_COMMENT, RUMBLE_VIDEO, MINDS, LBRY_VIDEO, LBRY_COMMENT, VK, TRUTH_SOCIAL, TIKTOK_VIDEO, TIKTOK_COMMENT, RUTUBE_COMMENT, RUTUBE_VIDEO, OK, BLUESKY, FEDIVERSE } = require('@/constants/sites')
const CONST_KEY = 'om_settings_hasConsented'

const fetchFromLocalStorage = (key) => {
  let value
  try {
    value = JSON.parse(window.localStorage.getItem(key))
  } catch (e) {
  }
  return value
}

export default {
  namespaced: true,
  state: {
    // TODO write tests about what the required fields are
    settings: {
      searchTerm: 'qanon',
      startDate: moment().subtract(1, 'year').format('YYYY-MM-DD'),
      endDate: moment().subtract(0.5, 'year').subtract(1, 'day').format('YYYY-MM-DD'),
      websites: [
        {
          name: EIGHTKUN,
          label: '8kun',
          active: false
        },
        {
          name: FOURCHAN,
          label: '4chan',
          active: false
        },
        {
          name: BLUESKY,
          label: 'Bluesky',
          active: false
        },
        {
          name: BITCHUTE_VIDEO,
          label: 'Bitchute Video',
          active: false
        },
        {
          name: BITCHUTE_COMMENT,
          label: 'Bitchute Comment',
          active: false
        },
        {
          name: FEDIVERSE,
          label: 'Fediverse',
          active: false
        },
        {
          name: GAB,
          label: 'Gab',
          active: true
        },
        {
          name: GETTR,
          label: 'Gettr',
          active: false
        },
        {
          name: LBRY_COMMENT,
          label: 'LBRY Comment',
          active: false
        },
        {
          name: LBRY_VIDEO,
          label: 'LBRY Video',
          active: false
        },
        {
          name: MEWE,
          label: 'MeWe',
          active: false
        },
        {
          name: MINDS,
          label: 'Minds',
          active: false
        },
        {
          name: OK,
          label: 'OK',
          active: false
        },
        {
          name: PARLER,
          label: 'Parler',
          active: false
        },
        {
          name: POAL,
          label: 'Poal',
          active: false
        },
        {
          name: RUMBLE_VIDEO,
          label: 'Rumble Video',
          active: false
        },
        {
          name: RUMBLE_COMMENT,
          label: 'Rumble Comment',
          active: false
        },
        {
          name: RUTUBE_VIDEO,
          label: 'RUTUBE Video',
          active: false
        },
        {
          name: RUTUBE_COMMENT,
          label: 'RUTUBE Comment',
          active: false
        },
        {
          name: TELEGRAM,
          label: 'Telegram',
          active: false
        },
        {
          name: TIKTOK_VIDEO,
          label: 'TikTok Video',
          active: false
        },
        {
          name: TIKTOK_COMMENT,
          label: 'TikTok Comment',
          active: false
        },
        {
          name: TRUTH_SOCIAL,
          label: 'Truth Social',
          active: false
        },
        {
          name: VK,
          label: 'VK',
          active: false
        },
        {
          name: WIMKIN,
          label: 'WiMKiN',
          active: false
        },
        {
          name: WIN,
          label: 'Win Communities',
          active: false
        }
      ],
      numberOf: 10,
      interval: 'day',
      limit: 10000,
      changepoint: false,
      esquery: 'content',
      hostRegex: true,
    },
    hasConsented: fetchFromLocalStorage(CONST_KEY),
    doSearch: {
      page: '', // e.g. "hashtags"
      count: 0 // kind of like a clock. when it increments, do another search
    },
    history: {
      doneSearchBefore: false,
      lastUsedTool: 'timeline'
    }
  },
  getters: {
    searchTerm (state) {
      return state.settings.searchTerm
    },
    startDate (state) {
      return state.settings.startDate
    },
    endDate (state) {
      return state.settings.endDate
    },
    websites (state) {
      return state.settings.websites
    },
    numberOf (state) {
      return state.settings.numberOf
    },
    interval (state) {
      return state.settings.interval
    },
    limit (state) {
      return state.settings.limit
    },
    changepoint (state) {
      return state.settings.changepoint
    },
    esquery (state) {
      return state.settings.esquery
    },
    hostRegex (state) {
      return state.settings.hostRegex
    },
    hasConsented (state) {
      return state.hasConsented
    }
  },
  mutations: {
    search (state, val) {
      state.doSearch.page = val
      state.doSearch.count++
    },
    setSearchTerm (state, val) {
      state.settings.searchTerm = val
    },
    setStartDate (state, val) {
      state.settings.startDate = val
    },
    setEndDate (state, val) {
      state.settings.endDate = val
    },
    setWebsiteActive (state, { i, val }) {
      state.settings.websites[i].active = val
    },
    resetWebsitesActiveState (state) {
      state.settings.websites.forEach(website => { website.active = false })
    },
    setNumberOf (state, val) {
      state.settings.numberOf = Number(val)
    },
    setInterval (state, val) {
      state.settings.interval = val
    },
    setLimit (state, val) {
      state.settings.limit = Number(val)
    },
    setChangepoint (state, val) {
      state.settings.changepoint = val
    },
    setEsQuery (state, val) {
      state.settings.esquery = val
    },
    setHostRegex (state, val) {
      state.settings.hostRegex = val
    },
    // todo: remove?
    setDoneSearchBefore (state) {
      state.history.doneSearchBefore = true
    },
    setLastUsedTool (state, val) {
      state.history.lastUsedTool = val
    },
    setHasConsented (state, val) {
      state.hasConsented = val
    }
  },
  actions: {
    acceptConsent ({ commit }) {
      commit('setHasConsented', true)
      try {
        window.localStorage.setItem(CONST_KEY, JSON.stringify(true))
      } catch (e) {}
    },
    loadSettings ({ state, commit }, val) {
      // WARNING this is dangerous!!
      // require guarentees for other getters/ mutations that state.settings is a particular shape
      state.settings = val
      // some components require the variables to be numbers so we put it
      // through the mutations which do that for us
      commit('setNumberOf', val.numberOf)
      commit('setLimit', val.limit)
      // it's a string in the url but we want a boolean
      commit('setChangepoint', val.changepoint === 'true')
      commit('setHostRegex', val.hostRegex === 'true')
      commit('setEsQuery', val.esquery)
    }
  }
}
