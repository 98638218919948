<template>
  <q-icon :name="name" :size="size" />
</template>

<script>
export default {
  props: {
    name: String,
    size: String
  }
}
</script>

<style scoped lang="scss"></style>
