<template>
  <div>
    <p> Search Term (<a href="https://docs.openmeasures.io/docs/guides/public-app#search-term-options" target="_blank">docs</a>)</p>
      <q-btn-toggle
        v-model="esquery"
        no-caps
        spread
        toggle-color="black-highlight"
        color="white"
        text-color="black"
        @update:model-value="setEsQuery"
        :options="[
          {label: 'Basic', value: 'content'},
          {label: 'Boolean', value: 'boolean_content'},
          {label: 'Advanced', value: 'query_string'},
        ]"
      >
    </q-btn-toggle>
  <InputSearchTermText
    :placeholder="placeholderText"
    :value="searchTerm"
    @input="setSearchTerm"
  />
</div>
</template>
<script>
import InputSearchTermText from './InputSearchTermText'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapMutations } = createNamespacedHelpers('searchSettings')

export default {
  components: {
    InputSearchTermText
  },
  computed: {
    ...mapGetters(['searchTerm']),
    ...mapGetters(['esquery']),
    placeholderText () {
      switch (this.esquery) {
        case 'content':
          return 'Example: qanon'
        case 'boolean_content':
          return 'Example: qanon OR wwg1wga'
        case 'query_string':
          return 'Example: (author: anon123 AND text: qanon)'
        default:
          return ''
      }
    }
  },
  methods: {
    ...mapMutations(['setSearchTerm']),
    ...mapMutations(['setEsQuery'])
  },
  watch: {
    esquery () {
      this.setSearchTerm('')
    }
  }
}
</script>

<style scoped lang="scss">
p {
  text-align: left;
  font-size: var(--fs-3);
  font-weight: 600;
  margin-bottom: var(--spacing-2);
  font: var(--primary-font);
}
</style>
