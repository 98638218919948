<template>
  <button @click="$emit('click')" v-ripple>
    <div class="inner" :style='{ paddingTop: paddingY, paddingBottom: paddingY, width: `calc(100% + 30*{paddingX})` }' :class="{ active }">
      <slot></slot>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    active: Boolean,
    paddingX: String,
    paddingY: String,
  }
}
</script>

<style scoped lang="scss">
button {
  background: none;
  border: none;
  color: var(--color-black);
  font-size: var(--fs-2);
  font-weight: 800;
  text-transform: uppercase;
  position: relative;
  width: 100%;
  cursor: pointer;
  // max-width: 60px;
}

.inner {
  border-bottom: solid 4px transparent;
  padding: 15px var(--spacing-1) 5px;
  letter-spacing: 1px;
  // max-width: 60px;
  width: 100%;
}


.inner.active {
  border-bottom: solid 4px var(--color-purple);
}

.inner:hover {
  border-bottom: solid 4px var(--color-purple);
}
</style>
