<template>
  <div>
    <form @submit.prevent="search('hashtags')">
      <ButtonOpenToolModal />

      <InputSearchTerm />

      <InputDates />

      <InputNumberOf :label="$t('setting.numberHashtags')" />

      <InputLimit />

      <FatButton>
        {{ $t("setting.button.hashtags") }}
      </FatButton>
    </form>
  </div>
</template>

<script>
import ButtonOpenToolModal from '@/components/input/tool-picker/ButtonOpenToolModal'
import { createNamespacedHelpers } from 'vuex'
import InputSearchTerm from '@/components/input/InputSearchTerm'
import InputDates from '@/components/input/InputDates'
import InputNumberOf from '@/components/input/InputNumberOf'
import InputLimit from '@/components/input/InputLimit'
import FatButton from '@/components/input/FatButton'
const { mapMutations } = createNamespacedHelpers('searchSettings')

export default {
  components: {
    ButtonOpenToolModal,
    InputSearchTerm,
    InputDates,
    InputNumberOf,
    InputLimit,
    FatButton
  },
  methods: {
    ...mapMutations(['search'])
  }
}
</script>

<style scoped lang="scss">
form > * {
  margin-top: var(--spacing-5);
}
</style>
