<template>
  <q-card class="card">
    <div class="top-part">
      <div class="title">
        <slot name="title"></slot>
      </div>
      <div class="info">
        <div>
          <slot name="info-left"></slot>
        </div>
        <div class="right">
          <slot name="info-right"></slot>
        </div>
      </div>
      <div class="content">
        <slot name="content"></slot>
      </div>
    </div>
    <div class="foot-divider" />
    <div class="card-foot">
      <div />
      <div>
        <slot name="footer"></slot>
      </div>
    </div>
  </q-card>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.card {
  background: var(--color-white);
  border-radius: var(--border-radius-3);
  overflow: hidden;
}

.top-part {
  margin: var(--spacing-4);
}

.title {
  font-size: var(--fs-5);
  font-weight: bold;
  color: var(--color-dark-text);
}

.info {
  display: grid;
  grid-auto-flow: column;
  margin-top: var(--spacing-1);
  font-size: var(--fs-3);
  font-weight: 500;
  color: var(--color-light-text);
}

.info .right {
  justify-self: end;
}

.content {
  margin-top: var(--spacing-3);
}

.foot-divider {
  width: 100%;
  height: 1px;
  background-color: var(--color-dividers);
}

.card-foot {
  display: grid;
  grid-template-columns: 1fr auto;
  margin: var(--spacing-4);
}
</style>
