<template>
  <div>
    <div v-if="resultsAvailable">
      <BaseCard>
        <template #title>
          {{ $t("chart.hashtagsWith") }}'{{
            lastSearchSettings ? lastSearchSettings.searchTerm : ""
          }}'
        </template>
        <template #info-left>
          {{ lastSearchSettings.startDate }}
          -
          {{ lastSearchSettings.endDate }}
        </template>
        <template #content>
          <BaseBarChart ref="chart" :resultsData="resultsData" />
        </template>
        <template #footer>
          <FatButton @click="$refs.chart.downloadImage()">
            {{ $t("chart.downloadPNG") }}
          </FatButton>
        </template>
      </BaseCard>

      <TableCount
        :card-title="$t('table.view')"
        :titles="[$t('table.hashtags'), $t('table.hashtagFreq')]"
        :resultsData="resultsData"
      />
    </div>
    <BodyLoading :loading="loading" />
    <BodyError :error="error" />
  </div>
</template>

<script>
import BaseCard from '@/components/BaseCard'
import BaseBarChart from '@/components/charts/BaseBarChart'
import FatButton from '@/components/input/FatButton'
import TableCount from '@/components/tables/TableCount'
import BodyLoading from './BodyLoading'
import BodyError from './BodyError'
import { isEmpty, flatten, uniq } from 'lodash'

export default {
  components: {
    BaseCard,
    BaseBarChart,
    FatButton,
    TableCount,
    BodyLoading,
    BodyError
  },
  props: {
    lastSearchSettings: Object,
    results: Array,
    resultsAvailable: Boolean,
    loading: Boolean,
    error: Error
  },
  computed: {
    /**
     * Takes the raw result from the fetch and turns it into a nice format
     * that we can use
     */
    resultsData () {
      // results[0].data.hits.hits[0]["_source"]["entities"]["hashtags"] is array
      if (this.results !== null) {
        const hits = this.results[0].data.hits.hits // this be a list
        const allHashtags = flatten(
          hits
            .map((hit) => hit._source.entities.hashtags)
            .filter((hashtag) => !isEmpty(hashtag))
        ).map((tag) => tag.text)

        const uniqueHashtags = uniq(allHashtags)
        const hashtags = uniqueHashtags.map((hashtag) => {
          const count = allHashtags.filter((tag) => tag === hashtag).length
          return {
            key: hashtag,
            count: count
          }
        })

        hashtags.sort((a, b) => b.count - a.count)

        return hashtags.slice(0, this.lastSearchSettings.numberOf)
      } else {
        return []
      }
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  margin-top: var(--spacing-4);
}
</style>
