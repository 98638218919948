<template>
  <div v-if='error != null'>
    <div class='human'>Search failed for some reason, please try again</div>

    <div class='error'>
      <div v-if='showDetail'>{{error}}</div>
      <a v-else href='#' @click.prevent="showDetail = true">Details > </a>
    </div>
  </div>
</template>

// TODO - style this some

<script>
export default {
  props: ['error'],
  data () {
    return {
      showDetail: false
    }
  }
}
</script>

<style scoped lang="scss">
.human {
  font-size: 18px;

  margin-bottom: 2rem;
}

.error {
  div {
    font-family: monospace;
    background: #fff;
    padding: 1rem;
  }

  a {
    color: #555;
  }
}
</style>
