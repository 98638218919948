import { flatten } from 'lodash'
const { TWITTER, GAB, FOURCHAN, EIGHTKUN, PARLER, WIN, POAL, TELEGRAM, GETTR, BITCHUTE_VIDEO, BITCHUTE_COMMENT, WIMKIN, MEWE, RUMBLE_COMMENT, RUMBLE_VIDEO, MINDS, LBRY_VIDEO, LBRY_COMMENT, VK, TRUTH_SOCIAL, TIKTOK_VIDEO, TIKTOK_COMMENT, RUTUBE_COMMENT, RUTUBE_VIDEO, OK, BLUESKY, FEDIVERSE } = require('@/constants/sites')

export function normaliseResults (website) {
  /**
  * Takes the raw result from the fetch and turns it into a nice format
  * that we can use
  */
  if (website === null) return []

  return website.map((results) => {
    const hits = results.data.hits.hits
    let allResults = []

    switch (results.name) {
      case TWITTER:
        allResults = flatten(
          hits
            .map(hit => ({
              text: hit._source.text,
              author: hit._source.screen_name,
              link: `https://twitter.com/${hit._source.screen_name}/status/${hit._source.id_str}`,
              retweet_count: hit._source.retweet_count,
              favorite_count: hit._source.favorite_count
            }))
        )
        break

      case TELEGRAM:
        allResults = flatten(
          hits
            .map(hit => ({
              board: hit._source.channelusername,
              link: `https://${hit._source.url}`,
              text: hit._source.message,
              retweet_count: hit._source.forwards
            }))
        )
        break

      case EIGHTKUN:
        allResults = flatten(
          hits
            .map(hit => ({
              board: hit._source.board,
              text: hit._source.htmlparsedcom,
              author: hit._source.name
            }))
        )
        break

      case VK:
        allResults = flatten(
          hits
            .map(hit => ({
              board: hit._source.wall_owner,
              text: hit._source.text,
              author: hit._source.author
            }))
        )
        break

      case TRUTH_SOCIAL:
        allResults = flatten(
          hits
            .map(hit => ({
              text: hit._source.content_cleaned,
              author: hit._source.account ? hit._source.account.acct : 'UNKNOWN',
              link: hit._source.url,
              retweet_count: hit._source.reblogs_count,
              favorite_count: hit._source.favourites_count
            }))
        )
        break

      case FOURCHAN:
        allResults = flatten(
          hits
            .map(hit => ({
              board: hit._source.board,
              text: hit._source.htmlparsedcom,
              author: hit._source.name
            }))
        )
        break

      case PARLER:
        allResults = flatten(
          hits
            .map(hit => ({
              text: hit._source.body,
              author: hit._source.name,
              link: `https://parler.com/posts/${hit._source.id}`,
              retweet_count: hit._source.reposts,
              favorite_count: hit._source.upvotes
            }))
        )
        break

      case MINDS:
        allResults = flatten(
          hits
            .map(hit => ({
              text: hit._source.body,
              author: hit._source.user.username,
              favorite_count: hit._source['thumbs:up:count']
            }))
        )
        break

      case GAB:
        allResults = flatten(
          hits
            .map(hit => ({
              text: hit._source.content,
              author: hit._source.account ? hit._source.account.acct : 'UNKNOWN',
              link: hit._source.account ? `https://gab.com/${hit._source.account.acct}/posts/${hit._source.id}` : 'UNKNOWN',
              retweet_count: hit._source.reblogscount,
              favorite_count: hit._source.favouritescount
            }))
        )
        break

      case POAL:
        allResults = flatten(
          hits
            .map(hit => ({
              board: hit._source.sub,
              text: hit._source.content,
              author: hit._source.user,
              favorite_count: hit._source.upvotes
            }))
        )
        break

      case MEWE:
        allResults = flatten(
          hits
            .map(hit => ({
              text: hit._source.content,
              author: hit._source.userid,
              favorite_count: hit._source.likes,
              link: hit._source.url
            }))
        )
        break

      case WIMKIN:
        allResults = flatten(
          hits
            .map(hit => ({
              text: hit._source.content,
              author: hit._source.author,
              favorite_count: hit._source.votes,
              link: hit._source.permalink
            }))
        )
        break

      case WIN:
        allResults = flatten(
          hits
            .map(hit => ({
              text: hit._source.html_parsed_html,
              author: hit._source.author,
              favorite_count: hit._source.votes
            }))
        )
        break

      case GETTR:
        allResults = flatten(
          hits
            .map(hit => ({
              text: hit._source.txt,
              author: hit._source.uid,
              link: `https://gettr.com/${hit._source.data_type.slice(0, -1)}/${hit._source.id}`,
            }))
        )
        break

      case BITCHUTE_VIDEO:
        allResults = flatten(
          hits
            .map(hit => ({
              author: hit._source.creator,
              text: hit._source.title,
              link: hit._source.meta.url,
            }))
        )
        break

      case BITCHUTE_COMMENT:
        allResults = flatten(
          hits
            .map(hit => ({
              author: hit._source.creator,
              text: hit._source.content
            }))
        )
        break

      case RUTUBE_VIDEO:
        allResults = flatten(
          hits
            .map(hit => ({
              author: hit._source.author.name ? hit._source.author.name : 'UNKNOWN',
              text: hit._source.title,
              link: hit._source.video_url,
              favorite_count: hit._source.likes
            }))
        )
        break

      case RUTUBE_COMMENT:
        allResults = flatten(
          hits
            .map(hit => ({
              author: hit._source.user.name ? hit._source.user.name : 'UNKNOWN',
              text: hit._source.text,
              favorite_count: hit._source.likes_number
            }))
        )
        break


      case TIKTOK_VIDEO:
        allResults = flatten(
          hits
            .map(hit => ({
              author: hit._source.author,
              text: hit._source.desc
            }))
        )
        break

      case TIKTOK_COMMENT:
        allResults = flatten(
          hits
            .map(hit => ({
              author: hit._source.author,
              text: hit._source.text
            }))
        )
        break

      case RUMBLE_VIDEO:
        allResults = flatten(
          hits
            .map(hit => ({
              author: hit._source.username,
              text: hit._source.title,
              link: hit._source.metadata.url,
              favorite_count: hit._source.likes
            }))
        )
        break

      case RUMBLE_COMMENT:
        allResults = flatten(
          hits
            .map(hit => ({
              author: hit._source.username,
              text: hit._source.text,
              favorite_count: hit._source.likes
            }))
        )
        break

      case LBRY_VIDEO:
        allResults = flatten(
          hits
            .map(hit => ({
              author: hit._source.signing_channel.name,
              text: hit._source.value.title
            }))
        )
        break

      case LBRY_COMMENT:
        allResults = flatten(
          hits
            .map(hit => ({
              author: hit._source.channel_name,
              text: hit._source.comment
            }))
        )
        break

      case OK:
        allResults = flatten(
          hits
            .map(hit => ({
              author: hit._source.author,
              text: hit._source.content
            }))
        )
        break

      case BLUESKY:
        allResults = flatten(
          hits
            .map(hit => ({
              author: hit._source.authorProfile.handle ? hit._source.authorProfile.handle : 'UNKNOWN',
              text: hit._source.text
            }))
        )
        break

      case FEDIVERSE:
        allResults = flatten(
          hits
            .map(hit => ({
              author: hit._source.account ? hit._source.account.acct : 'UNKNOWN',
              text: hit._source.content_cleaned
            }))
        )
        break

      default:
        console.error(`no result processor for ${website.name} links`)
    }

    return {
      label: results.label,
      data: allResults
    }
  })
}