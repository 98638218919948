<template>
  <q-inner-loading :showing="loading">
    <div class="spinner-container">
      <q-spinner size="100px" color="faded-gray" />
    </div>
  </q-inner-loading>
</template>

<script>
export default {
  props: {
    loading: Boolean
  }
}
</script>

<style scoped lang="scss">
.spinner-container {
  height: 100%;
  margin-top: 30vh;
}
</style>
