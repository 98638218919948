<template>
  <textarea
    v-auto-height
    :placeholder="placeholder"
    :value="value"
    rows="1"
    @input="$emit('input', $event.target.value), $emit('update:value', $event.target.value)"
    required
    maxlength="1000"
  />
</template>

<script>
// import InputContainer from './InputContainer'

export default {
  // components: {
  //   InputContainer
  // },
  props: {
    label: String,
    placeholder: String,
    value: String
  },
  emits: ['input', 'update:value'],
  directives: {
    'auto-height': {
      updated (el) {
        el.style.height = 'auto'
        el.style.height = el.scrollHeight + 'px'
      },
      mounted (el) {
        el.style.height = 'auto'
        el.style.height = el.scrollHeight + 'px'
      }
    }
  }
}
</script>

<style scoped lang="scss">

textarea {
  width: 100%;
  text-align: left;
  flex: auto;
  resize: none; /* disable resize */
  overflow: hidden; /* hide scrollbar */
  padding: var(--spacing-2);
  margin-top: var(--spacing-3); /* add top margin */
  // margin-bottom: var(--spacing-2);
  font: var(--primary-font);
  border: 1px solid black; /* add black border */

}
.slot {
  color: initial;
}

::placeholder {
  color: var(--color-light-text);
}
</style>