<template>
  <div id="app">
    <q-layout>
      <q-header>
        <TheTopBar
          @toggleToolBar="showSettingsBar = !showSettingsBar"
          @toggleMenu="showMenu = !showMenu"
        />
      </q-header>

      <template v-if="hasSettingsBar">
        <q-drawer
          v-if="large"
          v-model="drawer"
          show-if-above

          :mini="!drawer || miniState"

          :width="366"
          :breakpoint="500"
          bordered
        >
          <div
            class="sidebar"
          >
            <div>
              <div class='sidebar-title'>{{ $t("sidebar.title") }}</div>
              <div>
                <router-view name="settings" />
              </div>
            </div>
          </div>

          <template v-slot:mini>
            <q-scroll-area class="fit mini-slot cursor-pointer">
              <div class="column items-center" style='padding-top: 6px;' @click="miniState = false">
                <IconTool tool='openSidebar' :size='50' noBackground />
              </div>
            </q-scroll-area>
          </template>


          <div class="q-mini-drawer-hide absolute close-sidebar" @click="miniState = true">
            <IconTool tool='closeSidebar' :size='40' noBackground />
          </div>

        </q-drawer>
        <BaseModal v-else v-model:value="showSettingsBar">
          <TheSettingsBar />
        </BaseModal>
      </template>

      <q-page-container>
        <ThePage />
      </q-page-container>
    </q-layout>
  </div>
</template>

<script>
import TheTopBar from '@/components/TheTopBar'
import TheSettingsBar from '@/components/TheSettingsBar'
import IconTool from '@/components/input/tool-picker/IconTool'
import BaseModal from '@/components/BaseModal'
import ThePage from '@/components/ThePage'
import { createNamespacedHelpers } from 'vuex'

const { TIMELINE, HASHTAGS, LINKS, ACTIVITY, SEARCH } = require('@/constants/tools')

const { mapState: mapSettingsState } = createNamespacedHelpers('searchSettings')

export default {
  components: {
    TheTopBar,
    TheSettingsBar,
    ThePage,
    BaseModal,
    IconTool
  },
  data () {
    return {
      showSettingsBar: true,
      showMenu: false,
      large: false,
      drawer: true,
      miniState: false
    }
  },
  computed: {
    ...mapSettingsState(['doSearch']),
    hasMenu () {
      return this.showMenu
    },
    hasSettingsBar () {
      return [TIMELINE, HASHTAGS, LINKS, ACTIVITY, SEARCH].includes(this.$route.name)
    },
  },
  methods: {
    updateLarge () {
      this.large = window.matchMedia('(min-width: 1000px)').matches
    },
  },
  watch: {
    // when the user clicks Search, this variable is incremented, so we watch
    // it to find out when the user wants to search. a weird kind of event bus
    'doSearch.count': function () {
      if (!this.large) {
        this.showSettingsBar = false
      }
    },
  },
  created () {
    this.updateLarge()
    window.addEventListener('resize', this.$store.commit('setWindowWidth'))
    window.addEventListener('resize', this.updateLarge)
  }
}
</script>

<style lang="scss">
@import "global";
</style>

<style scoped lang="scss">
@import "~@openfonts/sarabun_all/index.css";

a {
  text-decoration: none !important;
}

#app {
  font-family: var(--primary-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-black);
  background: var(--color-dark);
  // min-height: 100vh;
  // height: 100%;
}

.toolbar {
  // background: var(--color-dark-bg);
  background: var(--color-off-white);
}

.sidebar {
  min-height: 100%;
  // background: var(--color-dark-bg);
  background: var(--color-off-white);
  // height: 100%;
  width: 100%;
  padding: var(--spacing-3) var(--spacing-4);
  text-align: left;
  color: var(--color-black);
  .sidebar-title {
    background: var(--color-off-white);
    color: var(--color-black);
    font-size: 20px;
    font-weight: bold;
  }
}

.mini-slot {
  background: var(--color-off-white);
  // height: 100%;
}

.close-sidebar {
  top: 10px;
  right: 20px;
  &:hover {
    cursor: pointer;
  }
}


</style>
